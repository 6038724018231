<template>
  <div>

    <br>

    <CarritoCompra :patentes="patentes" :e1="e1" @reload="reaload()"></CarritoCompra>

    <div v-if="loadSoap" class="text-center">
      <br>
      <br>
      <br>
      <br>
      <br>
      <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
      Cargando
    </div>

    <div v-if="loadSoap == false" class="top">
      <v-form class="text-center" ref="form" v-model="valid">

        <br>
        <br>

        <b class="obligatorio">SEGURO OBLIGATORIO SOAP 2023</b>

        <br>

        <b class="vigencia">Vigente desde el 1 de abril del 2023</b>

        <v-container style="justify-content: center; display: flex;">

          <v-col style="display:flex; ">

            <div>
              <label :class="vistaTituloPatente">Ingresa tu patente aquí:</label>
              <v-text-field :class="vistaLabelPatente" v-model="carData.patente" :rules="patenteRules" label="Ej: ABDC12"
                required filled solo @change="Mayus()">
              </v-text-field>
            </div>

            <div>
              <label :class="vistaTituloCorreo">Ingresa tu correo aquí:</label>
              <v-text-field :class="vistaLabelCorreo" v-model="propietarioData.email" label="Ej: miemail@mail.com"
                :rules="emailRules" required filled solo>
              </v-text-field>
            </div>

          </v-col>

        </v-container>

        <br>
        <br>

        <div
          style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; justify-content: center;">

          <vue-recaptcha ref="recaptcha" @verify="verifyMethod" sitekey="6LdZzfwiAAAAANWXDzgv_Qi4HVI3aV7SwHpwd8-u" />

        </div>

        <br>
        <br>

        <v-btn
          style="width: 310px; height: 64px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #2D387C; border: 1px solid #DEDEDE; border-radius: 30px;
            opacity: 1; text-align: center; font: normal normal normal 22px/27px Arial; letter-spacing: 0.66px; color: #D74E80; text-transform: uppercase; opacity: 1;"
          @click="validate()">
          Buscar
        </v-btn>

        <br>
        <br>
        <br>
        <br>

      </v-form>
    </div>

    <br>

    <div v-if="loadSoap == false" class="sub">

      <br>

      <v-col class="text-center"
        style="display: flex; flex-direction: row; justify-content: space-between; flex-wrap: wrap; justify-content: center;">

        <div v-if="loadPlans == false" style="margin:auto;">

          <br>

          <div class="rectangulo">

            <label class="titulo">
              {{ plans.soap.car.name }}
            </label>

            <br>
            <br>
            <br>
            <br>

            <v-img contain :src="Auto" max-height="130" max-width="130" style="margin:auto;" />

          </div>

          <div class="subrectangulo"
            style="padding: 5%; margin-top: -3%; background: #009DD6 0% 0% no-repeat padding-box;">

            <label class="subtitulo">
              {{ formatPrice(plans.soap.car.price) }}
            </label>

          </div>

        </div>

        <div v-if="loadPlans == false" style="margin:auto;">

          <br>

          <div class="rectangulo">

            <label class="titulo">
              {{ plans.soap.camioneta.name }}
            </label>

            <br>
            <br>
            <br>
            <br>

            <v-img contain :src="Camioneta" max-height="130" max-width="130" style="margin:auto;" />

          </div>

          <div class="subrectangulo"
            style="padding: 5%; margin-top: -3%; background: #138468 0% 0% no-repeat padding-box;">

            <label class="subtitulo">
              {{ formatPrice(plans.soap.camioneta.price) }}
            </label>

          </div>

        </div>

        <br>

        <div v-if="loadPlans == false" style="margin:auto;">

          <br>

          <div class="rectangulo">

            <label class="titulo">
              {{ plans.soap.moto.name }}
            </label>

            <br>
            <br>
            <br>
            <br>

            <v-img contain :src="Moto" max-height="140" max-width="130" style="margin:auto;" />

          </div>

          <div class="subrectangulo"
            style="padding: 5%; margin-top: -3%; background: #FB6330 0% 0% no-repeat padding-box;">

            <label class="subtitulo">
              {{ formatPrice(plans.soap.moto.price) }}
            </label>

          </div>

        </div>

        <br>

        <div v-if="loadPlans == false" style="margin:auto;">

          <br>

          <div class="rectangulo">

            <label class="titulo">
              {{ plans.soap.arrastre.name }}
            </label>

            <br>
            <br>
            <br>
            <br>

            <v-img contain :src="Carro" max-height="140" max-width="130" style="margin:auto;" />

          </div>

          <div class="subrectangulo"
            style="padding: 5%; margin-top: -3%; background: #7849B8 0% 0% no-repeat padding-box;">

            <label class="subtitulo">
              {{ formatPrice(plans.soap.arrastre.price) }}
            </label>

          </div>

        </div>

        <br>

        <div v-if="loadPlans == false" style="margin:auto;">

          <br>

          <div class="rectangulo">

            <label class="titulo">
              {{ plans.soap.bus.name }}
            </label>

            <br>
            <br>
            <br>
            <br>

            <v-img contain :src="Minibus" max-height="130" max-width="130" style="margin:auto;" />

          </div>

          <div class="subrectangulo"
            style="padding: 5%; margin-top: -3%; background: #EC111A 0% 0% no-repeat padding-box;">

            <label class="subtitulo">
              {{ formatPrice(plans.soap.bus.price) }}
            </label>

          </div>

        </div>

      </v-col>

      <br>
      <br>
      <br>

    </div>

  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import Auto from "@/assets/images/Auto.png";
import Camioneta from "@/assets/images/Camioneta.png";
import Carro from "@/assets/images/CARRO.png";
import Minibus from "@/assets/images/Minibus.png";
import Moto from "@/assets/images/Moto.png";
import ShieldService from "../services/shield.service";
import CarritoCompra from "@/components/Carrito.vue";
import swal from 'sweetalert';

export default {
  name: "Clientes",
  components: {
    VueRecaptcha,
    CarritoCompra,
  },
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    loadSoap: false,
    loadPlans: true,
    e1: 0,
    newCar: false,
    patentes: [],
    excluyentes: [],
    patenteUtils: null,
    loadingData: true,
    keygen: null,
    Auto,
    Camioneta,
    Carro,
    Minibus,
    Moto,
    captcha: null,
    screen: window.screen.width,
    vistaTituloPatente: null,
    vistaTituloCorreo: null,
    vistaLabelPatente: null,
    vistaLabelCorreo: null,
    carData: {
      patente: null,
      year: null,
      type: null,
      marca: null,
      model: null,
      motor: null,
    },
    propietarioData: {
      rut: null,
      nombres: null,
      firtlastname: null,
      secondlastname: null,
      email: null,
      phoneNumber: null,
    },
    plans: {},
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /.+@.+/.test(v) || "Ingrese un correo válido",
    ],
    patenteRules: [
      (v) => !!v || "El número de patente es requerido",
    ],
  }),
  methods: {
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async reaload() {
      location.reload();
    },
    async validate() {
      if (this.$refs.form.validate() && this.captcha == true) {
        localStorage.setItem('path', 'clientes')
        this.loadSoap = true
        var largoPatente = this.carData.patente.length
        if (largoPatente != 6) {
          var newPatente = this.carData.patente.match(/[a-z]+|[^a-z]+/gi)
          this.carData.patente = newPatente[0] + '0' + newPatente[1]
        }
        if (this.excluyentes.includes(this.carData.patente) == true) {
          swal({
            title: "Patente duplicada!",
            text: "Ya ha ingresado esta patente al carrito de compra",
            icon: "info",
            button: "Ok",
          });
          this.loadSoap = false
        } else {
          var patenteAprobada = await ShieldService.consultasoapAprobado(this.carData.patente)
          if (patenteAprobada.status == 'ok') {
            swal({
              title: "Patente registrada!",
              text: "Esta patente ya cuenta con un seguro SOAP",
              icon: "info",
              button: "Ok",
            });
          } else {
            if (this.newCar == true) {
              window.location.href = process.env.VUE_APP_DOMINIO + 'clientes/cotizador' + '?patente=' + this.carData.patente + '&email=' + this.propietarioData.email + '&newCar=' + this.newCar + '&type=soap-clientes-scotia'
            } else {
              window.location.href = process.env.VUE_APP_DOMINIO + 'clientes/cotizador' + '?patente=' + this.carData.patente + '&email=' + this.propietarioData.email + '&type=soap-clientes-scotia'
            }
          }
          this.loadSoap = false
        }
      }
    },
    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },
    verifyMethod() {
      this.captcha = true
    },
    Mayus() {
      this.carData.patente = this.carData.patente.toUpperCase()
    },
  },
  async mounted() {
    const query = this.$route.query;
    var shieldRes = await ShieldService.getPlans('soap-clientes-scotia')
    if (shieldRes.status == 'ok') {
      this.plans = shieldRes.data[0]
      this.loadPlans = false
    }
    if (query.newCar == 'true') {
      this.newCar = true
      this.keygen = localStorage.getItem('keygen')
      this.excluyentes = []
      this.patentes = []
      await ShieldService.getCars(this.keygen).then((response) => {
        if (response.status == 'ok') {
          response.data.forEach(car => {
            this.patentes.push(car)
            this.excluyentes.push(car.patenteNumber)
          });
        }
      })
      this.loadingData = false
    }
    if (this.screen > 800) {
      this.vistaTituloPatente = 'labelNamePatente'
      this.vistaTituloCorreo = 'labelNameCorreo'
      this.vistaLabelPatente = 'labelPatente'
      this.vistaLabelCorreo = 'labelCorreo'
    } else if (this.screen <= 800) {
      this.vistaTituloPatente = 'labelNamePatente2'
      this.vistaTituloCorreo = 'labelNameCorreo2'
      this.vistaLabelPatente = 'labelPatente2'
      this.vistaLabelCorreo = 'labelCorreo2'
    }
  },
};
</script>

<style lang="css" scoped>
.obligatorio {
  text-align: center;
  font: Arial;
  font-size: 30px;
  letter-spacing: 0px;
  color: #573C81;
  opacity: 1;
}

.vigencia {
  width: 244px;
  height: 17px;
  text-align: center;
  font: Arial;
  font-size: 15px;
  letter-spacing: 0px;
  color: #2D387C;
  opacity: 1;
}

.top {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.sub {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
}

.titulo {
  text-align: center;
  font: Arial;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: #262626;
  opacity: 1;
}

.subtitulo {
  width: 102px;
  text-align: center;
  font: Arial;
  font-size: 32px;
  letter-spacing: 0.96px;
  color: #FFFFFF;
  opacity: 1;
}

.rectangulo {
  width: 216px;
  height: 286px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
}

.subrectangulo {
  width: 216px;
  height: 65px;
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
}

.labelPatente {
  width: 400px;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 40%;
  text-transform: uppercase;
  font-weight: bold;
}

.labelCorreo {
  width: 400px;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 54%
}

.labelNamePatente {
  margin-left: 15%;
  text-align: left;
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
}

.labelNameCorreo {
  margin-left: 42%;
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
}

.labelPatente2 {
  width: 90%;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  text-transform: uppercase;
  font-weight: bold;
}

.labelCorreo2 {
  width: 90%;
  height: 58px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
}

.labelNamePatente2 {
  text-align: left;
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  margin-left: -10%;
}

.labelNameCorreo2 {
  font: Arial;
  font-size: 13px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  margin-left: -10%;
}
</style>