<template>
  <v-app>
    <v-app-bar :class="navVar">

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import LogoDesktop from "@/assets/images/Desktop.png";
import LogoMovil from "@/assets/images/Movil.png";

export default {
  components: {
    Footer,
  },
  data: () => ({
    LogoDesktop,
    LogoMovil,
    screen: window.screen.width,
    navVar: '',
  }),
  mounted() {
    if (this.screen > 800) {
      this.navVar = 'navVar'
    } else if (this.screen <= 800) {
      this.navVar = 'navVar2'
    }
  }
};
</script>

<style>
.navVar {
  background-image: url("~@/assets/images/Desktop.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  min-height: 200px;
}

.navVar2 {
  background-image: url("~@/assets/images/Movil.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  min-height: 200px;
}

.titulo {
  text-align: center;
  font: Arial;
  font-size: 40px;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}

.subtitulo {
  text-align: center;
  font: Arial;
  font-size: 31px;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
}

@font-face {
  font-family: "Scotia_Bd";
  src: local("Scotia_Bd"),
    url(/fonts/Scotia_Bd.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_BdIt";
  src: local("Scotia_BdIt"),
    url(/fonts/Scotia_BdIt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Headline";
  src: local("Scotia_Headline"),
    url(/fonts/Scotia_Headline.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_It";
  src: local("Scotia_It"),
    url(/fonts/Scotia_It.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Legal";
  src: local("Scotia_Legal"),
    url(/fonts/Scotia_Legal.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Lt";
  src: local("Scotia_Lt"),
    url(/fonts/Scotia_Lt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_LtIt";
  src: local("Scotia_LtIt"),
    url(/fonts/Scotia_LtIt.ttf) format("truetype");
}

@font-face {
  font-family: "Scotia_Rg";
  src: local("Scotia_Rg"),
    url(/fonts/Scotia_Rg.otf) format("truetype");
}
</style>